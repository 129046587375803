import React, { useEffect, useState } from "react";

import { List, ListItem, ListItemText } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import { navigate } from "gatsby-link";
import styled from "styled-components";
import Swal from "sweetalert2";

import PrivateRoute from "~/components/Authentication/PrivateRoute";
import SButton from "~/components/Buttons/SButton";
import Flex from "~/components/Containers/Flex";
import Wrapper from "~/components/Containers/Wrapper";
import Error from "~/components/Errors/Error";
import useDebounce from "~/components/hooks/useDebounce";
import { elasticSearchAPI, packAPI } from "~/utils/api/v2";

const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: no-wrap;
  align-items: center;
  justify-content: center;

  ${(props: { margin?: string }) => {
    if (props.margin) {
      return `margin: ${props.margin};`;
    }
  }}

  .MuiFormControl-root {
    margin: 5px 10px;
  }
`;

type Pack = {
  active: boolean;
  category: string;
  description: string;
  price: number;
  fonasa_price: number;
  image: string;
  is_familiar: boolean;
  name: string;
  needs_service_receipt: boolean;
  number_of_patients: number;
  results_time: number;
  slug: string;
  allows_more_items_in_cart: boolean;
  stackable: boolean;
  allows_fonasa: boolean;
  country: "cl" | "mx";
  blocks_needed: number;
  items: string[];
};

type Items = {
  id: string;
  name: string;
};

const NewPack = (): JSX.Element => {
  const [errors, setErrors] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [categories, setCategories] = useState<string[]>([]);
  const [items, setItems] = useState<Items[]>([]);
  const [query, setQuery] = useState<string>("");
  const [packData, setPackData] = useState<Pack>({
    active: false,
    category: "",
    image: "",
    description: "",
    price: 0,
    fonasa_price: 0,
    is_familiar: false,
    name: "",
    needs_service_receipt: false,
    number_of_patients: 0,
    results_time: 24,
    slug: "",
    allows_more_items_in_cart: true,
    stackable: true,
    allows_fonasa: false,
    country: "cl",
    blocks_needed: 0,
    items: [],
  });

  const fetchItems = async (query: string) => {
    const { data } = await elasticSearchAPI.newSearch({
      query,
      countryCode: packData.country,
      source: "marketplace",
    });
    if (data) {
      setItems(data);
    }
  };

  const invokeDebounce = useDebounce(async () => await fetchItems(query), 300, {
    leading: false,
    trailing: true,
  });

  useEffect(() => {
    if (query) {
      invokeDebounce();
    }
  }, [query]);

  const fetchCategories = async (): Promise<void> => {
    setLoading(true);
    try {
      const res = await packAPI.listCategories({ country: packData.country });
      setCategories(res.data.map(({ category }) => category));
    } catch (err) {
      setErrors([err.message]);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchCategories();
  }, [packData.country]);

  const onSubmit = async () => {
    setLoading(true);
    const errors = checkErrors();
    if (errors) {
      setLoading(false);
      return;
    }

    try {
      const { status } = await packAPI.create(packData);
      Swal.fire({
        icon: status === 201 ? "success" : "error",
        title: status === 201 ? "Pack creado con éxito" : "Error al crear pack",
        text: status === 201 ? `Pack ${packData.name} creado` : "Error al crear el pack",
        showConfirmButton: true,
        didClose: () => {
          navigate(-1);
        },
      });
    } catch (err) {
      console.error(err);
      setErrors([err.message]);
    }
    setLoading(false);
  };

  const formChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const newValue = event.target.value;
    const attribute = event.target.id;
    setPackData((prevState: Pack): Pack => {
      const newState = { ...prevState };
      newState[attribute] = newValue;
      return newState;
    });
  };

  const checkErrors = () => {
    const slugRegex = /^[a-z0-9]+(?:-[a-z0-9]+)*$/;
    const newErrors: string[] = [];
    if (!packData.name) {
      newErrors.push("El nombre del pack es obligatorio");
    }
    if (!packData.slug) {
      newErrors.push("El slug del pack es obligatorio");
    } else if (!slugRegex.test(packData.slug)) {
      newErrors.push("El slug del pack no es válido (solo letras minúsculas y guiones)");
    }
    if (!packData.category) {
      newErrors.push("La categoría del pack es obligatoria");
    }
    if (packData.price === 0) {
      newErrors.push("El precio del pack es obligatorio");
    }
    if (packData.allows_fonasa && packData.fonasa_price === 0) {
      newErrors.push("El precio de fonasa del pack es obligatorio");
    }
    if (packData.is_familiar && packData.number_of_patients === 0) {
      newErrors.push("El número de pacientes del pack es obligatorio");
    }
    if (packData.items.length === 0) {
      newErrors.push("El pack debe tener al menos un item");
    }
    setErrors(newErrors);
    return newErrors.length ? true : false;
  };

  return (
    <PrivateRoute>
      <Flex justify="center">
        <Wrapper variant="outlined">
          <h2>Crear nuevo pack</h2>

          <Error>
            <ul>
              {errors.map((i, key) => (
                <li key={key}>{i}</li>
              ))}
            </ul>
          </Error>
          <Row>
            <FormControl fullWidth>
              <InputLabel id="country">País</InputLabel>
              <Select
                labelId="country"
                id="country"
                value={packData.country}
                onChange={(e) => {
                  setPackData({ ...packData, country: e.target.value as any });
                  setItems([]);
                  setQuery("");
                }}
              >
                <MenuItem value="cl">Chile</MenuItem>
                <MenuItem value="mx">México</MenuItem>
              </Select>
            </FormControl>
            {packData.country === "cl" && (
              <FormControl fullWidth>
                <InputLabel id="allows_fonasa">¿Permite fonasa?</InputLabel>
                <Select
                  labelId="allows_fonasa"
                  id="allows_fonasa"
                  value={packData.allows_fonasa}
                  onChange={(e) =>
                    setPackData({
                      ...packData,
                      allows_fonasa: e.target.value as any,
                    })
                  }
                >
                  <MenuItem value={true as any}>Sí</MenuItem>
                  <MenuItem value={false as any}>No</MenuItem>
                </Select>
              </FormControl>
            )}
          </Row>
          <Row>
            <TextField
              id="name"
              onChange={formChange}
              value={packData.name}
              helperText="Nombre del pack"
              fullWidth
            />
            <TextField
              id="slug"
              onChange={formChange}
              value={packData.slug}
              helperText="Slug"
              fullWidth
            />
          </Row>
          <Row>
            <TextField
              id="description"
              onChange={formChange}
              value={packData.description}
              helperText="Descripción"
              fullWidth
            />
          </Row>
          <Row>
            <TextField
              id="price"
              type="number"
              onChange={formChange}
              value={packData.price}
              helperText="Precio final"
              fullWidth
              inputProps={{ min: 0 }}
            />
            <TextField
              id="fonasa_price"
              type="number"
              onChange={formChange}
              value={packData.fonasa_price}
              helperText="Precio Fonasa"
              fullWidth
              inputProps={{ min: 0 }}
            />
          </Row>
          <Row>
            <TextField
              id="image"
              onChange={formChange}
              value={packData.image}
              helperText="Link de la imagen de portada"
              fullWidth
            />
            <TextField
              id="results_hours"
              type="number"
              onChange={formChange}
              value={packData.results_time}
              helperText="Tiempo de resultados"
              fullWidth
              inputProps={{ min: 0 }}
            />
          </Row>
          <Row>
            <FormControl fullWidth>
              <InputLabel id="is_familiar">¿Es pack familiar?</InputLabel>
              <Select
                value={packData.is_familiar}
                onChange={(e) => {
                  setPackData({
                    ...packData,
                    is_familiar: e.target.value as boolean,
                  });
                }}
                fullWidth
              >
                <MenuItem
                  key="true"
                  value={true as any}
                >
                  Si
                </MenuItem>
                <MenuItem
                  key="false"
                  value={false as any}
                >
                  No
                </MenuItem>
              </Select>
            </FormControl>
            <TextField
              id="number_of_patients"
              type="number"
              onChange={formChange}
              value={packData.number_of_patients}
              helperText="Número de pacientes"
              fullWidth
              inputProps={{ min: 0 }}
            />
          </Row>
          <Row>
            <FormControl fullWidth>
              <InputLabel id="needs_service_receipt">¿Necesita boleta de examedi?</InputLabel>
              <Select
                value={packData.needs_service_receipt}
                onChange={(e) => {
                  setPackData({
                    ...packData,
                    needs_service_receipt: e.target.value as boolean,
                  });
                }}
                fullWidth
              >
                <MenuItem
                  key="true"
                  value={true as any}
                >
                  Si
                </MenuItem>
                <MenuItem
                  key="false"
                  value={false as any}
                >
                  No
                </MenuItem>
              </Select>
            </FormControl>
          </Row>
          <Row>
            <FormControl fullWidth>
              <InputLabel id="allows_more_items_in_cart">¿Permite más items en el carro?</InputLabel>
              <Select
                value={packData.allows_more_items_in_cart}
                onChange={(e) => {
                  setPackData({
                    ...packData,
                    allows_more_items_in_cart: e.target.value as boolean,
                  });
                }}
                fullWidth
              >
                <MenuItem
                  key="true"
                  value={true as any}
                >
                  Si
                </MenuItem>
                <MenuItem
                  key="false"
                  value={false as any}
                >
                  No
                </MenuItem>
              </Select>
            </FormControl>
          </Row>
          <Row>
            <FormControl fullWidth>
              <InputLabel id="stackable">Stackable</InputLabel>
              <Select
                value={packData.stackable}
                onChange={(e) => {
                  setPackData({
                    ...packData,
                    stackable: e.target.value as boolean,
                  });
                }}
                fullWidth
              >
                <MenuItem
                  key="true"
                  value={true as any}
                >
                  Si
                </MenuItem>
                <MenuItem
                  key="false"
                  value={false as any}
                >
                  No
                </MenuItem>
              </Select>
            </FormControl>
            <TextField
              id="blocks_needed"
              type="number"
              fullWidth
              onChange={formChange}
              value={packData.blocks_needed}
              helperText="Bloques necesarios"
              inputProps={{ min: 0 }}
            />
          </Row>
          <Row>
            <FormControl fullWidth>
              <InputLabel id="category">Categoría</InputLabel>
              <Select
                value={packData.category}
                onChange={(e) => {
                  setPackData({
                    ...packData,
                    category: e.target.value as string,
                  });
                }}
                fullWidth
              >
                {categories.map((category, index) => (
                  <MenuItem
                    key={index}
                    value={category}
                  >
                    {category}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth>
              <InputLabel id="active">Activo</InputLabel>
              <Select
                value={packData.active}
                onChange={(e) => {
                  setPackData({
                    ...packData,
                    active: e.target.value as boolean,
                  });
                }}
                fullWidth
              >
                <MenuItem
                  key="true"
                  value={true as any}
                >
                  Si
                </MenuItem>
                <MenuItem
                  key="false"
                  value={false as any}
                >
                  No
                </MenuItem>
              </Select>
            </FormControl>
          </Row>
          <Row>
            <TextField
              id="search_items"
              fullWidth
              onChange={(e) => setQuery(e.target.value)}
              value={query}
              helperText="Buscar items"
            />
          </Row>
          <Row>
            <List
              component="nav"
              aria-label="main mailbox folders"
              style={{ width: "100%" }}
            >
              {items
                .filter((item) => !packData.items.includes(item.id))
                .map((item) => (
                  <ListItem
                    button
                    key={item.id}
                    onClick={() => {
                      setPackData({
                        ...packData,
                        items: [...packData.items, item.id],
                      });
                    }}
                  >
                    <ListItemText primary={item.name} />
                  </ListItem>
                ))}
            </List>
          </Row>
          <Flex
            align="center"
            justify="center"
            margin="2rem 0rem 1rem 0rem"
          >
            <SButton
              variant="outlined"
              color="secondary"
              disabled={loading}
              onClick={() => {
                navigate(-1);
              }}
            >
              Cancelar
            </SButton>
            <SButton
              variant="outlined"
              color="primary"
              disabled={loading}
              onClick={onSubmit}
            >
              Crear
            </SButton>
          </Flex>
        </Wrapper>
      </Flex>
    </PrivateRoute>
  );
};

export default NewPack;
